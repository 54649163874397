<template>
  <div>
    <van-search v-model="pageParams.orderNo" placeholder="请输入订单号搜索" @search="loadData" @clear="searchClear" />
    <div class="order-list">
      <div>
        <van-dropdown-menu style="margin: 0 15px; margin-top: 5px" class="menu">
          <van-dropdown-item title="创建时间" ref="menuTime" :title-class="pageParams.time ? 'title_active' : ''">
            <div class="calendar-wrap">
              <van-calendar v-model="showCalendar" :show-confirm="false" :poppable="false" type="range" position="top"
                :min-date="minDate" :show-title="false" :style="{ height: '330px' }" row-height="40"
                @confirm="onTimeConfirm" />
            </div>
          </van-dropdown-item>
          <van-dropdown-item title="订单状态" v-model="pageParams.orderStatus" :options="orderStatusList"
            :title-class="pageParams.orderStatus ? 'title-active' : ''" @change="loadData" />
        </van-dropdown-menu>
      </div>
      <div v-if="lists.length > 0">
        <van-list v-model="loading" :finished="finished" :immediate-check="false" :offset="10" finished-text="没有更多了"
          @load="onLoadMore">
          <div v-for="(item, index) in lists" :key="index" class="order-item">
            <div class="order-no">
              <div class="flex-1">订单号:{{ item.orderNo }}</div>
              <div class="status" :class="`status${item.orderStatus}`">{{ item.orderStatus | statusName }}</div>
            </div>
            <div class="info-item" style="margin-top: 13px">
              <div class="flex-shrink">创建时间</div>
              <div class="flex-1 text-right">{{ item.createTime | dateFormat }}</div>
            </div>
            <div v-if="item.returnTime" class="info-item">
              <div class="flex-shrink">归还时间</div>
              <div class="flex-1 text-right">{{ item.returnTime | dateFormat }}</div>
            </div>
            <div class="money-wrap">
              <div class="money-item">
                <div class="text-money"><span style="font-size: 13px;">¥</span>{{ item.orderAmount }}</div>
                <div class="text-label">订单金额</div>
              </div>
              <div class="money-item">
                <div class="text-money">{{ item.merchantShareBenefitRate }}%</div>
                <div class="text-label">分成比例</div>
              </div>
              <div class="money-item">
                <div class="text-money"><span style="font-size: 13px;">¥</span>{{ item.merchantRevenue }}</div>
                <div class="text-label">分成金额</div>
              </div>
            </div>
          </div>
        </van-list>
      </div>
      <van-empty v-else description="暂无数据" />
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import { getPowerbankOrderList } from 'api/powerbank'

const statusList = [
  { text: '全部' },
  { text: '发起租借', value: 0 },
  { text: '租借失败', value: 1 },
  { text: '已租借', value: 2 },
  { text: '已完成', value: 3 },
  { text: '超时完成', value: 4 },
  { text: '扣款失败', value: 5 },
  { text: '待确认', value: 6 },
  { text: '已取消', value: 7 },
  { text: '待结算', value: 8 },
  { text: '扣款中', value: 9 },
  { text: '退款中', value: 10 },
  { text: '重新付款中', value: 11 },
]

export default {
  name: 'Order',
  data() {
    return {
      lists: [],
      pageParams: {
        orderNo: '',
        orderStatus: '',
        pageSize: 10,
        page: 1,
      },
      orderStatusList: statusList,
      total: 0, //总共的数据条数
      loading: false,
      finished: false,
      minDate: dayjs()
        .subtract(1, 'year')
        .toDate(),
      maxDate: dayjs().toDate(),
      showCalendar: true,
    }
  },
  filters: {
    statusName(val) {
      return statusList.find(item => item.value == val)?.text
    },
    dateFormat(val) {
      return dayjs(val).format('YYYY-MM-DD')
    },
  },
  created() {
    console.log('minDate', this.minDate)
    this.loadData()
  },
  methods: {
    searchClear() {
      this.pageParams.orderNo = ''
      this.loadData()
    },
    loadData() {
      const params = {
        ...this.pageParams,
      }
      getPowerbankOrderList(params).then(res => {
        const { code, data } = res
        console.log('getPowerbankOrderList', data)
        if (code === 200) {
          const rows = data.list //请求返回当页的列表
          this.loading = false
          this.total = data.total
          if (rows == null || rows.length === 0) {
            this.finished = true
          }

          // 将新数据与老数据进行合并
          this.lists = this.pageParams.page == 1 ? rows : this.lists.concat(rows)

          //如果列表数据条数>=总条数，不再触发滚动加载
          if (this.lists.length >= this.total) {
            this.finished = true
          }
        } else {
          this.$toast.fail(res.msg)
        }
      })
    },
    onTimeConfirm(e) {
      this.pageParams.startCreateTime = dayjs(e[0]).format('YYYY-MM-DD 00:00:00')
      this.pageParams.endCreateTime = dayjs(e[1]).format('YYYY-MM-DD 23:59:59')
      this.$refs.menuTime.toggle()
      this.loadData()
    },
    onLoadMore() {
      this.pageParams.page++
      this.loadData()
    },
  },
}
</script>

<style lang="less" scoped>
.flex-1 {
  flex: 1;
}

.flex-shrink {
  flex-shrink: 0;
}

.text-right {
  text-align: right;
}

.order-list {
  padding: 0 15px;
}

.order-item {
  margin-bottom: 10px;
  padding-bottom: 12px;
  border-radius: 4px;
  background-color: #fff;

  .order-no {
    position: relative;
    display: flex;
    align-items: center;
    padding: 13px 15px;
    font-size: 14px;
    color: #333;
    border-bottom: 1px solid #eee;
    word-break: break-all;

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: calc(50% - 7px);
      width: 5px;
      height: 14px;
      background: linear-gradient(180deg, rgba(253, 110, 5, 0.5) 0%, rgba(253, 110, 5, 1) 100%);
    }
  }

  .status {
    flex-shrink: 0;
    padding-left: 10px;
    font-size: 14px;
    color: #999;

    &0,
    &1,
    &5,
    &7,
    &9,
    &10 {
      color: #fe4242;
    }
  }

  .info-item {
    display: flex;
    align-items: center;
    margin-top: 8px;
    padding: 0 15px;
    color: #1e2431;
    font-size: 14px;
    opacity: 0.7;
  }

  .money-wrap {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    margin: 10px 15px 0 15px;
    padding: 11px;
    border-radius: 5px;
    background-color: #fbfbfb;

    .money-item {
      padding: 0 5px;
      text-align: center;

      .text-money {
        color: #333;
        font-weight: 500;
        font-size: 17px;
      }

      .text-label {
        margin-top: 4px;
        font-size: 11px;
        color: #999;
      }
    }
  }
}

.menu {
  margin: 15px 0 15px 0 !important;
}

.title-active {
  color: #fd6e05 !important;
}

/deep/.van-search__content {
  background: none;
  border-radius: 16px;
  border: 1px solid #fd6e05;
}

/deep/.van-dropdown-menu__bar {
  box-shadow: none !important;
}

/deep/.van-popup--top {
  width: 345px;
  margin-left: -172.5px;
  left: 50%;
}

/deep/.van-dropdown-menu__title::after {
  margin-top: -6px;
  border: 4px solid;
  border-color: transparent transparent #333333 #333333;
}

/deep/.van-dropdown-menu__title--active::after {
  margin-top: -0px;
  border-color: transparent transparent #fd6e05 #fd6e05;
}

.calendar-wrap {
  /deep/.van-calendar {
    position: relative;
  }

  /deep/.van-popup--top {
    top: 125px;
  }

  /deep/.van-calendar__popup .van-popup__close-icon {
    display: none;
  }

  /deep/.van-overlay {
    display: none;
  }
}

/deep/.van-calendar__day--start,
/deep/.van-calendar__day--end {
  background: #fd6e05;
}

/deep/.van-calendar__bottom-info {
  display: none;
}

/deep/.van-calendar__day--middle {
  color: #fd6e05;
  background: rgba(253, 110, 5, 0.13);
}

/deep/.van-button--danger {
  width: 46%;
  float: right;
  border: none;
  background: linear-gradient(89deg, rgba(253, 110, 5, 0.66) 10%, #fd6e05 81%);
}

/deep/.van-dropdown-menu__title--active {
  color: #fd6e05;
}

/deep/.van-dropdown-item__option--active {
  color: #fd6e05;
}

/deep/.van-dropdown-item__option--active .van-dropdown-item__icon {
  color: #fd6e05;
}
</style>
